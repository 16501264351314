import { Injectable } from '@angular/core';
import { ClientsUsersRequests } from '@app/shared/interfaces/clients-users/clients-users.request';
import { ClientUser } from '@app/shared/interfaces/clients-users/clients-users.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';
import { AbstractHelper } from '../../abstract/abstract-helper.class';
import { HttpUpdateMethods } from '../../abstract/abstract.service';

@Injectable()
export class ClientsUsersService extends AbstractCrudService<
  ClientUser,
  ClientsUsersRequests
> {
  protected override feature = 'clients-users';
  protected override endPoint = `/clients-users/`;
  
  updateBulk(clientUserRoleId: string, clientsUsersIds: string[]): AbstractHelper<ClientUser> {
    const endpoint = `${this.endPoint}*/bulk`;
    const userModel = {
      clientUserRoleId,
      clientsUsersIds,
    };
  return this.update(HttpUpdateMethods.PUT, '', userModel, [], endpoint);
}
}
